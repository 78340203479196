import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Col, Row, Space, Tooltip } from "antd";
import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const AuthForm = (props) => {
  const {
    onFinish,
    loading,
    userDefaultValues,
    price,
    currency,
    isWithIdentificationNumber = true,
    soldOut = false,
  } = props;
  const [form] = Form.useForm();

  const [isMobile, setIsMobile] = React.useState(false);
  const dimensions = useWindowDimensions();

  const buttonStyle = {
    ...styles.buttonSubmit,
    boxShadow: soldOut ? "0px 0px 20px #292D32" : "0px 0px 20px #63D664",
    background: soldOut ? "#292D32" : "#63D664",
  };

  React.useEffect(() => {
    if (dimensions?.width < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [dimensions]);

  const buttonText = () => {
    if (price > 0) {
      return soldOut
        ? "Sold Out"
        : `Pagar ${currency ? currency : "$"} ${price}`;
    } else {
      return "Registrarme";
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      disabled={soldOut}
      requiredMark={false}
      initialValues={{ user: userDefaultValues }}
    >
      <Row style={{ height: "100%" }} gutter={24}>
        <Col span={12}>
          <Form.Item
            className="fieldLabel"
            name={["user", "firstName"]}
            label="Nombre"
            rules={[{ required: true, message: "El nombre es requerido" }]}
            style={styles.formLabel}
          >
            <Input
              className="input"
              style={styles.input}
              placeholder={"Nombre"}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["user", "lastName"]}
            label="Apellido"
            rules={[{ required: true, message: "El apellido es requerido" }]}
            style={styles.formLabel}
          >
            <Input
              className="input"
              style={styles.input}
              placeholder={"Apellido"}
            />
          </Form.Item>
        </Col>
      </Row>

      {isWithIdentificationNumber ? (
        <Row style={{ height: "100%" }} gutter={24}>
          <Col span={12}>
            <Form.Item
              name={["user", "phone"]}
              label="Número de teléfono"
              rules={[
                { required: true, message: "Número de teléfono es requerido" },
              ]}
              style={styles.formLabel}
            >
              <Input
                className="input"
                style={styles.input}
                placeholder={"Número de teléfono"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={["user", "identificationNumber"]}
              label="DNI"
              rules={[{ required: true, message: "El DNI es requerido" }]}
              style={styles.formLabel}
            >
              <Input
                className="input"
                style={styles.input}
                placeholder={"DNI"}
              />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <>
          <Form.Item
            name={["user", "phone"]}
            label="Número de teléfono"
            rules={[
              { required: true, message: "Número de teléfono es requerido" },
            ]}
            style={styles.formLabel}
          >
            <Input
              className="input"
              style={styles.input}
              placeholder={"Número de teléfono"}
            />
          </Form.Item>
        </>
      )}

      <Form.Item
        name={["user", "email"]}
        label={
          <div>
            Correo electrónico{" "}
            <Tooltip
              title={
                "Si ya tienes una cuenta creada en toket, utiliza el mismo email."
              }
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        }
        rules={[
          { type: "email", message: "Correo inválido" },
          { required: true, message: "El correo electrónico es requerido" },
        ]}
        style={styles.formLabel}
      >
        <Input
          className="input"
          style={styles.input}
          placeholder={"Correo electrónico"}
        />
      </Form.Item>
      <Form.Item
        label={"Repite tu correo electrónico"}
        name={["repeatEmail"]}
        style={styles.formLabel}
        required
        rules={[
          {
            message: "Los correo electrónicos deben ser idénticos",
            validator: (_, value) => {
              if (value === form.getFieldValue(["user", "email"])) {
                return Promise.resolve();
              } else {
                return Promise.reject();
              }
            },
          },
        ]}
      >
        <Input
          className="input"
          style={styles.input}
          placeholder={"Correo electrónico"}
        />
      </Form.Item>

      <Space
        style={isMobile ? styles.buttonContainerMob : styles.buttonContainer}
      >
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            loading={loading}
            size="large"
            type="primary"
            htmlType="submit"
            disabled={soldOut || loading}
            style={buttonStyle}
          >
            {buttonText()}
          </Button>
        </Form.Item>
        <h5 style={styles.poweredText}>
          Powered by{" "}
          <a
            style={styles.toket}
            target="_blank"
            href={"https://toket.io"}
            rel="noreferrer"
          >
            Toket
          </a>
        </h5>
      </Space>
    </Form>
  );
};

const styles = {
  formLabel: {
    fontWeight: "bold",
  },
  input: {
    background: "#292D32",
    color: "rgba(255, 255, 255, 0.6)",
    border: "none",
    borderRadius: "12px",
    padding: "7px 10px",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  buttonContainerMob: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginBottom: "40px",
  },
  buttonSubmit: {
    borderRadius: "100px",
    fontWeight: 700,
    height: "50px",
    width: "250px",
    marginTop: "20px",
  },
  poweredText: {
    color: "#292D32",
    fontSize: "20px",
    marginRight: "10px",
  },
  toket: {
    fontWeight: 700,
    color: "#63d66461",
  },
};

export default AuthForm;
