import Moment from "moment";
import React from "react";
import { Image, Row, Col } from "antd";

export default function TicketCard(props) {
  const { eventName, type, startDate, endDate, image, price, currency } = props;

  const priceText = () => {
    if (price > 0) {
      return `${currency ? currency : "$"} ${price}`;
    } else {
      return "GRATIS";
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.topHalf}>
        <Row>
          <Col span={24}>
            <h1 style={styles.titleCard}>{eventName}</h1>
          </Col>
        </Row>
        <Row style={styles.ticketContText}>
          <div>
            <p style={styles.label}>{"Ticket"}</p>
            <b>{type}</b>
          </div>
        </Row>
        <Row gutter={[0, 16]} style={styles.timeContText}>
          <Col span={12}>
            <div>
              <p style={styles.label}>{Moment(startDate).format("ll")}</p>
              <b>{Moment(startDate).format("LT")}</b>
            </div>
          </Col>

          {endDate && (
            <Col span={12}>
              <div>
                <p style={styles.label}>{Moment(endDate).format("ll")}</p>
                <b>{Moment(endDate).format("LT")}</b>
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <h4 style={styles.priceTitle}>{priceText()}</h4>
          </Col>
        </Row>
      </div>

      <div style={styles.half}>
        <div style={styles.ellipseLeft} />
        <div style={styles.lineMiddle} />
        <div style={styles.ellipseRight} />
      </div>

      <div style={styles.bottomHalf}>
        <Image src={image} style={styles.image} />
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: "65%",
    display: "flex",
    flexDirection: "column",
    aspectRatio: 0.5,
    alignItems: "stretch",
    justifyContent: "flex-start",
    borderRadius: 25,
    overflow: "hidden",
    backgroundColor: "#292D32",
  },
  topHalf: {
    height: "50%",
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
    justifyContent: "space-between",
    paddingTop: "9%",
    // paddingBottom: "9%",
    paddingLeft: "9%",
    paddingRight: "9%",
  },
  bottomHalf: {
    height: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "9%",
    paddingRight: "9%",
  },
  image: {
    width: "100%",
    aspectRatio: 1,
    borderRadius: 8,
    overflow: "hidden",
    borderColor: "white",
    borderWidth: 1,
  },
  titleCard: {
    color: "#63D664",
    fontWeight: 700,
    lineHeight: "28px",
    fontSize: "24px",
  },
  priceTitle: {
    color: "#63D664",
    fontWeight: 700,
    lineHeight: "28px",
    fontSize: "20px",
  },
  label: {
    margin: 0,
    lineHeight: "14px",
    fontSize: "12px",
  },
  ticketContText: {
    margin: "20px 0 15px",
  },
  timeContText: {
    marginBottom: "20px",
  },
  half: {
    height: "30px",
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  lineMiddle: {
    width: "80%",
    border: "1px dashed #54575b",
  },
  ellipseLeft: {
    width: "30px",
    height: "100%",
    background: "black",
    borderRadius: "50%",
    position: "absolute",
    left: "-20px",
  },
  ellipseRight: {
    width: "30px",
    height: "100%",
    background: "black",
    borderRadius: "50%",
    position: "absolute",
    right: "-20px",
  },
};
