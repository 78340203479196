import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import { useSearchParams } from "react-router-dom";
import { getEventDetail, createSession } from "../../api/firebase";
import Spinner from "../Spinner";
import AuthForm from "./AuthForm";
import TicketCard from "../TicketCard";
import { Alert, Button, Col, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function EventDetail() {
  const { eventId } = useParams();
  const [searchParams] = useSearchParams();

  const [loadingEvent, setLoadingEvent] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userDefaultValues, setUserDefaultValues] = useState(null);
  const [backUrl, setBackUrl] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const dimensions = useWindowDimensions();

  const soldOut = eventDetails ? eventDetails.tickets?.[0]?.soldOut : false;

  useEffect(() => {
    (async () => {
      try {
        setLoadingEvent(true);
        const eventResult = await getEventDetail({ eventId: eventId });
        setEventDetails(eventResult.data);
        setLoadingEvent(false);
      } catch (error) {
        console.error("*AC error: ", error);
        setLoadingEvent(false);
      }
    })();
  }, [eventId]);

  useEffect(() => {
    setUserDefaultValues({
      firstName: searchParams.get("firstName"),
      lastName: searchParams.get("lastName"),
      email: searchParams.get("email"),
      phone: searchParams.get("phone"),
    });
    setBackUrl(searchParams.get("backUrl"));
  }, [searchParams]);

  useEffect(() => {
    if (dimensions?.width < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [dimensions]);

  if (loadingEvent) {
    return (
      <div style={styles.loadingContainer}>
        <Spinner />
      </div>
    );
  }

  const navigateBack = () => {
    if (!backUrl) {
      return;
    }
    window.location.replace(backUrl);
  };

  const onFormFinish = async (data) => {
    setLoading(true);

    try {
      const createIntentResult = await createSession({
        customer: data.user,
        backUrl: backUrl,
        eventId: eventId,
        ticketId: eventDetails.tickets[0].id,
      });

      window.location.replace(createIntentResult.data.url);
      setLoading(false);
    } catch (error) {
      console.error("*AC error: ", error);
      setLoading(false);
    }
  };

  return (
    <Row style={isMobile ? styles.containerMob : styles.container}>
      <Col xs={24} style={styles.formCol}>
        {soldOut && (
          <Alert
            showIcon
            message="Atención"
            description="Actualmente no hay entradas disponibles"
            type={"info"}
            banner
            style={styles.alert}
          />
        )}
      </Col>
      {eventDetails != null && (
        <>
          <Col xs={24} md={15} style={styles.formCol}>
            <div style={styles.formContainer}>
              {backUrl && (
                <Button onClick={navigateBack} style={styles.backButton}>
                  <ArrowLeftOutlined /> Volver
                </Button>
              )}
              <h4 style={styles.informationTitle}>Información de contacto</h4>
              <AuthForm
                loading={loading}
                userDefaultValues={userDefaultValues}
                onFinish={onFormFinish}
                currency={eventDetails.tickets?.[0]?.currency}
                price={eventDetails.tickets?.[0]?.price}
                soldOut={soldOut}
              />
            </div>
          </Col>
          <Col xs={0} md={2} style={styles.formCol}>
            <div style={styles.divider} />
          </Col>
          <Col
            xs={24}
            md={7}
            style={isMobile ? styles.cardColMob : styles.formCol}
          >
            <h4 style={styles.nftTitle}>NFT ticket</h4>

            {eventDetails.tickets.map((item) => {
              return (
                <TicketCard
                  type={item.name}
                  image={item.image}
                  price={item.price}
                  currency={item.currency}
                  eventName={eventDetails.name}
                  startDate={eventDetails.startDate}
                  endDate={eventDetails.endDate}
                  key={item.name}
                />
              );
            })}
          </Col>
        </>
      )}
    </Row>
  );
}

const styles = {
  container: {
    padding: 60,
    height: "100vh",
  },
  containerMob: {
    padding: "30px 0",
    height: "100vh",
  },
  detailsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
  },
  divider: {
    width: "1px",
    height: "70%",
    backgroundColor: "#292D32",
  },
  backButton: {
    background: "#FFFFFF",
    color: "#292D32",
    fontWeight: 700,
    fontSize: "14px",
    height: "40px",
    borderRadius: "40px",
  },
  informationTitle: {
    fontWeight: 700,
    fontSize: "35px",
    margin: "35px 0",
  },
  formCol: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardColMob: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "30px",
  },
  formContainer: {
    width: "80%",
  },
  nftTitle: {
    fontSize: "32px",
    fontWeight: 700,
  },
  loadingContainer: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  alert: {
    width: "100%",
  },
};
