import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { firestore, getOrderDetail } from "../../api/firebase";
import Spinner from "../Spinner";
import { doc, onSnapshot } from "firebase/firestore";
import { Alert, Button, Col, Result, Row, Spin } from "antd";
import Moment from "moment";
import TicketCard from "../TicketCard";
import LogoGoogle from "../../assets/logo-google.png";
import LogoToket from "../../assets/logo-toket.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function OrderDetail() {
  const { orderId } = useParams();
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderNotFound, setOrderNotFound] = useState(false);
  const [loadingTransaction, setLoadingTransaction] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const dimensions = useWindowDimensions();

  useEffect(() => {
    if (dimensions?.width < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [dimensions]);

  useEffect(() => {
    setLoadingTransaction(true);

    if (!orderDetails || !orderDetails?.transactionHash) {
      return;
    }

    const txn = orderDetails.transactionHash;
    onSnapshot(doc(firestore, "transactions", txn), (doc) => {
      if (!doc.exists()) {
        setLoadingTransaction(false);
        return;
      }
      setTransactionDetails({ id: doc.id, ...doc.data() });
      setLoadingTransaction(false);
    });
  }, [orderDetails]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const orderDetailResult = await getOrderDetail({ orderId: orderId });
        setOrderDetails(orderDetailResult.data);
        setLoading(false);
      } catch (error) {
        console.error("*AC error: ", error);
        setOrderNotFound(true);
        setLoading(false);
      }
    })();
  }, [orderId]);

  if (orderNotFound) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    );
  }

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <Row style={styles.container}>
      {orderDetails != null && (
        <>
          {orderDetails.newUserEmail && (
            <Alert
              showIcon
              message={
                <h3 style={styles.alertText}>
                  Mandamos un email a{" "}
                  <strong>{orderDetails.newUserEmail}</strong> con una
                  contraseña temporal para iniciar sesión en la app de{" "}
                  <strong>Toket</strong>
                </h3>
              }
              banner
              closable
              type={"success"}
              style={styles.alert}
            />
          )}
          <Col
            xs={24}
            md={14}
            style={isMobile ? styles.successColMob : styles.successCol}
          >
            <div
              style={
                isMobile
                  ? styles.informationContainerMob
                  : styles.informationContainer
              }
            >
              <h1 style={styles.informationTitle}>Listo!</h1>
              <p style={styles.informationSubtitle}>Compraste un ticket para</p>
              <p style={styles.informationDesc}>{orderDetails.event.name}</p>
              <p style={styles.informationDesc}>
                {Moment(orderDetails.event.startDate).format("ll")}
              </p>
              <p style={styles.informationDesc}>
                {Moment(orderDetails.event.startDate).format("LT")}
              </p>
            </div>
            <div style={styles.downloadToket}>
              <h4 style={styles.downloadToketTitle}>Descarga Toket Wallet</h4>
              <p style={styles.downloadSubtitle}>
                La vas a necesitar para ingresar al evento
              </p>
              <div style={styles.downloadImagesContainer}>
                <img
                  src={LogoToket}
                  alt={"Toket Logo"}
                  style={styles.toketLogo}
                />
                <div style={styles.downloadButtonsContainer}>
                  <a
                    href={"https://apps.apple.com/us/app/toket/id1618851888"}
                    target="_blank"
                    rel="noreferrer"
                    style={styles.downloadButtonLink}
                  >
                    <img
                      src={
                        "https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                      }
                      alt={"AppleLogo"}
                    />
                  </a>
                  <a
                    href={
                      "https://play.google.com/store/apps/details?id=com.Toket.app&hl=en&gl=US"
                    }
                    target="_blank"
                    rel="noreferrer"
                    style={styles.downloadButtonLink}
                  >
                    <button style={styles.downloadButton}>
                      <img src={LogoGoogle} alt={"Google Logo"} />
                      <div>
                        <p style={styles.downloadButtonDesc}>GET IT ON</p>
                        <h4 style={styles.downloadButtonTitle}>Google Play</h4>
                      </div>
                    </button>
                  </a>
                </div>
              </div>
            </div>
            {orderDetails?.transactionHash && (
              <div
                style={
                  isMobile ? styles.transactionHashMob : styles.transactionHash
                }
              >
                <div
                  style={
                    isMobile
                      ? styles.transactionHashTextsMob
                      : styles.transactionHashTexts
                  }
                >
                  <h5 style={styles.transactionHashTitle}>Transaction Hash</h5>
                  <p style={styles.transactionHashValue}>
                    {`${orderDetails.transactionHash.substring(
                      0,
                      10
                    )}...${orderDetails.transactionHash.substring(
                      orderDetails.transactionHash.length - 10
                    )}`}
                  </p>
                </div>

                {!transactionDetails ||
                loadingTransaction ||
                transactionDetails.status !== "mined" ? (
                  <Spin />
                ) : (
                  <a
                    target="_blank"
                    href={`https://polygonscan.com/tx/${orderDetails.transactionHash}`}
                    rel="noreferrer"
                  >
                    <Button style={styles.openPolygonButton}>
                      Open Polygonscan
                    </Button>
                  </a>
                )}
              </div>
            )}
          </Col>
          <Col xs={0} md={2} style={styles.orderCol}>
            <div style={styles.divider} />
          </Col>
          <Col
            xs={24}
            md={8}
            style={isMobile ? styles.cardColMob : styles.orderCol}
          >
            {orderDetails.items.map((item) => {
              return (
                <TicketCard
                  type={item.name}
                  image={item.image}
                  price={item.price}
                  currency={item.currency}
                  eventName={orderDetails.event.name}
                  startDate={orderDetails.event.startDate}
                  endDate={orderDetails.event.endDate}
                  key={item.name}
                />
              );
            })}
          </Col>
        </>
      )}
    </Row>
  );
}

const styles = {
  container: {
    padding: 30,
    height: "100vh",
  },
  orderCol: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardColMob: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "30px",
  },
  successCol: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "60px",
    paddingRight: 0,
  },
  successColMob: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: 0,
    alignItems: "center",
    textAlign: "center",
  },
  orderContainer: {
    width: "80%",
  },
  divider: {
    width: "1px",
    height: "60%",
    backgroundColor: "#292D32",
  },
  informationContainer: {
    marginBottom: "30px",
  },
  informationContainerMob: {
    marginBottom: "30px",
    textAlign: "center",
  },
  informationTitle: {
    fontWeight: 700,
    fontSize: "45px",
    margin: 0,
  },
  informationSubtitle: {
    fontWeight: 700,
    fontSize: "18px",
  },
  informationDesc: {
    fontSize: "18px",
    marginBottom: "5px",
  },
  downloadToket: {
    margin: "30px 0",
  },
  downloadToketTitle: {
    fontSize: "32px",
    fontWeight: 700,
    margin: 0,
  },
  downloadSubtitle: {
    fontSize: "14px",
    fontWeight: 700,
  },
  downloadImagesContainer: {
    display: "flex",
  },
  toketLogo: {
    margin: "20px",
    marginLeft: 0,
  },
  downloadButtonLink: {
    color: "inherit",
  },
  downloadButton: {
    border: "1px solid #A6A6A6",
    background: "transparent",
    borderRadius: "6px",
    width: "120px",
    height: "40px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: "5px 0",
    textAlign: "left",
    cursor: "pointer",
  },
  downloadButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  downloadButtonDesc: {
    fontSize: "9px",
    fontFamily: "Helvetica Neue",
    margin: 0,
  },
  downloadButtonTitle: {
    margin: 0,
    fontWeight: 700,
  },
  transactionHash: {
    backgroundColor: "#292D32",
    padding: "10px 30px",
    borderRadius: 18,
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  transactionHashMob: {
    backgroundColor: "#292D32",
    padding: 15,
    borderRadius: 18,
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    marginBottom: "40px",
  },
  transactionHashTexts: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    overflow: "hidden",
  },
  transactionHashTextsMob: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    width: "90%",
  },
  transactionHashTitle: {
    fontSize: "18px",
    lineHeight: "24px",
  },
  transactionHashValue: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
    overflow: "scroll",
  },
  openPolygonButton: {
    background: "#FFFFFF",
    color: "#292D32",
    fontWeight: 700,
    fontSize: "14px",
    height: "40px",
    borderRadius: "40px",
    marginLeft: "20px",
  },
  loadingContainer: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  alert: {
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  alertText: {
    margin: 0,
  },
};
