import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 58,
        }}
        spin
    />
);

export default function Spinner() {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
        }}>
            <Spin indicator={antIcon} />
        </div>
    )
}