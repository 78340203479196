import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/App.css";
import EventDetail from "./components/checkout/EventDetail";
import OrderDetail from "./components/checkout/OrderDetail";

export default function App() {
  return (
    <Router>
      <div style={{ padding: 0 }}>
        <Routes>
          <Route exact path="/" element={<div />} />
          <Route path="/event/:eventId" element={<EventDetail />} />
          <Route path="/order/:orderId" element={<OrderDetail />} />
        </Routes>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.
